export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>A propos</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>
              Je me souviens comme si c'&eacute;tait hier de ce repas chez mes parents il y a quelques mois. A la fin d'un tr&egrave;s bon repas, ma m&egrave;re me servit un nougat... &agrave; tomber par terre! J'ai insist&eacute; pour savoir dans quelle &eacute;picerie italienne ils se l'&eacute;taient procur&eacute;, mais quand ils m'avou&egrave;rent l'avoir fait eux-m&ecirc;mes, j'eus beaucoup de mal &agrave; y croire tant il &eacute;tait d&eacute;licieux. Le lendemain j'ai demand&eacute; &agrave; en faire avec eux pour en avoir le coeur net, je ne m'&eacute;tais jamais demand&eacute; comment en fabriquer d'ailleurs.
							<br/>Ce nougat me rappelait celui que je mangeais &eacute;tant jeune lors de mes vacances en Sardaigne.
							<br/>J'en ai donc fait go&ucirc;ter &agrave; mes amis et &agrave; chaque fois la m&ecirc;me r&eacute;action et le m&ecirc;me plaisir.
							<br/>Je me suis passionn&eacute; pour la fabrication et l'id&eacute;e &agrave; germer d'en faire une vraie activit&eacute;, en proposant un produit fait maison, dans un souci de d&eacute;veloppement durable, des commandes sur mon secteur avec un produit variant selon mes envies et mes disponibilit&eacute;s. Actuellement je propose un nougat &agrave; base de miel des Alpes qui lui donne un go&ucirc;t tr&egrave;s puissant.
							<br/>
							<br/>Prochainement confection &agrave; partir de miel de Moselle!
							</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
