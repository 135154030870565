export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Nos produits</h2>
          <p>
            Pourquoi choisir notre nougat?
          </p>
        </div>
        <div className='row'>
                <div key={`feat-1`} className='col-md-4'>
                  {' '}
                  <i className={'fa fa-home'}></i>
                  <div className='service-desc'>
                    <h3>Produit frais</h3>
                    <p>Ni trop dur, ni trop mou, juste ce qu'il faut d'onctuosit&eacute;.</p>
                  </div>
                </div>
                <div key={`feat-2`} className='col-md-4'>
                  {' '}
                  <i className={'fa fa-home'}></i>
                  <div className='service-desc'>
                    <h3>100% faits maison</h3>
                    <p>L'assurance de trouver des produits &agrave; l'oppos&eacute; des standards commerciaux.</p>
                  </div>
                </div>
                 <div key={`feat-3`} className='col-md-4'>
                  {' '}
                  <i className={'fa fa-home'}></i>
                  <div className='service-desc'>
                    <h3>Des ingr&eacute;dients de qualit&eacute;</h3>
                    <p>A base de miel bien entendu, peu de sucre ajout&eacute; (vous serez surpris), amandes, pistaches, blancs d'oeuf et zeste de citron bio.</p>
                  </div>
                </div>
        </div>
      </div>
    </div>
  )
}
