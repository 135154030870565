export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Ce que nos clients en pensent</h2>
        </div>
        <div className='row'>
                <div key={`1`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src='img/testimonials/01.jpg' alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <p>"Quelle d&eacute;couverte ce nougat, un d&eacute;lice! Pr&eacute;parez vous &agrave; succomber..."</p>
                      <div className='testimonial-meta'> -  Jennifer</div>
                    </div>
                  </div>
                </div>

                <div key={`1`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src='img/testimonials/02.png' alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <p>"Un nougat d'une saveur in&eacute;galable, pr&eacute;par&eacute; avec le respect des recettes ancestrales sardes transmises de g&eacute;n&eacute;ration en g&eacute;n&eacute;ration. C'est une vraie tuerie."</p>
                      <div className='testimonial-meta'> -  S&eacute;bastien</div>
                    </div>
                  </div>
                </div>

                <div key={`1`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src='img/testimonials/06.jpg' alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <p>"J'ai d&eacute;couvert les nougats Demelas lors d'un repas entre amis, quelle d&eacute;couverte! Ils sont tout simplement exquis. Vous pouvez commander les yeux ferm&eacute;s."</p>
                      <div className='testimonial-meta'> -  Arnaud</div>
                    </div>
                  </div>
                </div>

        </div>
      </div>
    </div>
  )
}
