import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Pour commander</h2>
                <p>
                  Nous contacter par mail (<a href='mailto:contact@nougatdemelas.com'>contact@nougatdemelas.com</a>) ou en MP sur nos comptes <a href={props.data ? props.data.facebook : '/'}>Facebook</a> / <a href={props.data ? props.data.instagram : '/'}>Instagram</a>, r&eacute;ponse dans les 24 heures et livraisons possibles secteurs Thionville - Metz.
                </p>
                <p>
                  De base, vente en barre de 150g (10 EUR) ou par bloc de 400g (65 EUR/kg). 
                </p>
                <p>
                  Rien de fig&eacute;, n'h&eacute;sitez pas &agrave; nous demander!
                </p>
                <br/>
                <p>
									Notre nougat sera disponible prochainement sur certains points de vente, &agrave; suivre ...
                </p>
              </div>
              {/*<form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Envoyer!
                </button>
              </form>*/}
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
						<div className='contact-item'>
							<img src={"img/team/01.jpg"} alt='...' className='team-img' />
						</div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2021-2023 Julien Demelas -- site d&eacute;velopp&eacute; par Olivier Parisot{' '}(
            <a target="blank" href='https://www.doopyon.org' rel='nofollow'>
              www.doopyon.org
            </a>
            )
          </p>
        </div>
      </div>
    </div>
  )
}
